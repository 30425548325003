<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "Download",
      items: [
        {
          text: "WORKANY",
          to:'/'
        },
        {
          text: "Download",
          active: true
        }
      ],
      appWin: "",
      appMac: "",
      appUbuntu: ""
    };
  },
  methods: {
    initAppUrls() {
      this.appWin = process.env.VUE_APP_DESKTOP_APP_WIN
      this.appMac = process.env.VUE_APP_DESKTOP_APP_MAC
      this.appUbuntu = process.env.VUE_APP_DESKTOP_APP_UBUNTU
    }
  },
  async created() {
    await this.initAppUrls()
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center mt-3">
          <div class="w-100">
            <div class="d-flex justify-content-center">
              <img src="@/assets/images/bg/empty.png" height="300px" width="auto" alt="logo"/>
            </div>
            <h4 class="font-size-28 text-center">Ready to track?</h4>
            <p class="text-center">Please download our desktop app and track work activities</p>
<!--            <div class="d-flex justify-content-center mt-3 download-btn">
              <b-dropdown size="lg" variant="primary">
                <template v-slot:button-content>
                  DOWNLOAD APP
                  <i class="mdi mdi-chevron-down"></i>
                </template>
                <b-dropdown-item :href="appWin">For Windows</b-dropdown-item>
                <b-dropdown-item :href="appMac">For Mac</b-dropdown-item>
                <b-dropdown-item :href="appUbuntu">For Ubuntu</b-dropdown-item>
              </b-dropdown>
            </div>-->
            <div class="d-flex justify-content-center mt-3">
              <b-btn :href="appWin" class="customs-bg-color ml-2" size="lg"><i class="ri-windows-line" style="vertical-align:bottom"></i> Windows</b-btn>
              <b-btn :href="appMac" class="customs-bg-color ml-2" size="lg"><i class="ri-apple-line" style="vertical-align:bottom"></i> Mac</b-btn>
              <b-btn :href="appUbuntu" class="customs-bg-color ml-2" size="lg"> <i class="ri-ubuntu-line" style="vertical-align:bottom"></i> Ubuntu</b-btn>
            </div>
          </div>
        </div>
      </div>

    </div>
  </Layout>
</template>


<style scoped>
.customs-bg-color {
  background-color: #ff7c47 !important;
  border-color: #ff7c47;

}

.download-btn .btn-primary {
  background-color: #ff7c47 !important;
  border-color: #ff7c47;

}
</style>
